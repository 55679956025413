import React from "react";
import FrontRoutes from "./FrontRoutes";
import { useParams } from "react-router-dom";
import useFrontStore, { FrontStoreContainer } from "./FrontStore";
import FrontLayout from "./FrontLayout";
import useFrontEventModel from "./hooks/useFrontEventModel";
import { Settings } from "../event/views";
import { IKContext } from "imagekitio-react";
import { ErrorEvent } from "./views";
const Front = (props) => {
	const { eventID } = useParams();
	const { event, getEventRT } = useFrontEventModel({
		eventID,
	});
	const { setEventDatas, setPlanningsDatas } = useFrontStore.useContainer();

	React.useEffect(() => {
		if (!event) {
			getEventRT();
		}
	}, []);

	React.useEffect(() => {
		setEventDatas(event);
	}, [event]);

	return (
		<>
			{event && (
				<FrontLayout
					authorID={event?.uid}
					eventID={eventID}
					style={event?.style}
					active_modules_global={event?.active_modules_global}
				>
					<IKContext urlEndpoint="https://ik.imagekit.io/jpwiy1sze">
						<FrontRoutes />
					</IKContext>
				</FrontLayout>
			)}
		</>
	);
};

export default FrontStoreContainer(Front);
