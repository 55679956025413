import React from "react";
import { Box, Typography, List, Switch, Stack, IconButton } from "@mui/joy";
import {
	Event,
	Timelapse,
	Groups,
	Star,
	Edit,
	Delete,
	SettingsOutlined,
	RemoveRedEyeOutlined,
} from "@mui/icons-material";
import Helpers from "utils/Helpers";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

const DefaultCard = ({
	event_name,
	event_date_stopped,
	status,
	id,
	changeStatus,
	hideStatus,
	event_background_image,
	event_date_period,
	editLink,
	showLink,
	event_start_date,
	event_end_date,
	event_day_duration,
	event_type,
	event_participants_qty,
	setSelectedId,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<Box
			sx={{
				border: "1px solid #888",
				borderRadius: "7px",
				width: "100%",

				"@media(min-width:1080px)": {},
				"@media(min-width:1280px)": {
					width: "48.6%",
				},
				"@media(min-width:1600px)": {
					width: "49.6%",
				},
				"@media(min-width:1680px)": {
					width: "32.6%",
				},
				"@media(min-width:1920px)": {
					width: "24.5%",
				},
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				onClick={() =>
					showLink
						? history.push(showLink)
						: history.push(`/event/${id}/general`)
				}
				sx={(theme) => ({
					"&:hover": {
						".title": {
							color: theme.vars.palette.primary[400],
						},
						".image": {
							opacity: 0.7,
						},
					},
					cursor: "pointer",
					p: 3,
					display: "flex",
					flexDirection: "row-reverse",
					justifyContent: "space-between",
					alignItems: "center",
					flexWrap: "wrap",
					"@media(max-width:1280px)": {
						justifyContent: "left",
					},
				})}
			>
				<Box
					className="image"
					sx={{
						background: `url(${event_background_image}) center center / cover`,
						width: "120px",
						height: "120px",
						borderRadius: "10px",
						"@media(max-width:1280px)": {
							width: "100%",
						},
					}}
				></Box>
				<Box
					sx={{
						maxWidth: "268px",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
				>
					<Typography
						className="title"
						sx={{
							textOverflow: "ellipsis",
							fontSize: "18px",
							fontWeight: "bold",
							mb: 2,
							"@media(max-width:1280px)": {
								mt: 1,
							},
						}}
					>
						{event_name}
					</Typography>
					<List
						sx={(theme) => ({
							margin: 0,
							padding: 0,
							listStyle: "none",
							li: {
								listStyle: "none",
							},
							".MuiSvgIcon-root": {
								color: theme.vars.palette.primary[400],
							},
						})}
					>
						{(event_date_period ||
							event_start_date ||
							event_end_date) && (
							<li>
								<Typography>
									<Event />{" "}
									{!event_date_stopped
										? `${t(
												event_date_period.split("_")[0],
										  )} ${
												event_date_period.split("_")[1]
										  } `
										: `${t("from")} ${Helpers.fbDate(
												event_start_date,
												"DD/MM/YYYY",
										  )} ${t("to")} ${Helpers.fbDate(
												event_end_date,
												"DD/MM/YYYY",
										  )} `}
								</Typography>
							</li>
						)}
						{!event_date_stopped && event_day_duration && (
							<li>
								<Timelapse /> {event_day_duration} {t("days")}
							</li>
						)}

						{event_participants_qty && (
							<li>
								<Groups /> {event_participants_qty}{" "}
								{t("peoples")}
							</li>
						)}
						{event_type && (
							<li>
								<Star /> {t(event_type)}
							</li>
						)}
					</List>
				</Box>
			</Box>
			<Stack
				sx={{ p: "10px 20px", borderTop: "1px solid #888" }}
				direction="row"
				justifyContent="space-between"
			>
				{!hideStatus && (
					<Switch
						checked={status === "active"}
						variant="solid"
						onChange={(e) => changeStatus(e, id)}
					/>
				)}
				<Stack spacing={1} direction="row">
					<IconButton
						onClick={() =>
							editLink
								? history.push(editLink)
								: history.push(`/event/${id}/general`)
						}
						variant="outlined"
					>
						<Edit />
					</IconButton>
					<IconButton
						component={Link}
						target="_blank"
						to={showLink ? showLink : `/my_event/${id}`}
						variant="outlined"
					>
						<RemoveRedEyeOutlined />
					</IconButton>
					<IconButton
						onClick={() => {
							setSelectedId(id);
						}}
						variant="outlined"
					>
						<Delete />
					</IconButton>
				</Stack>
			</Stack>
		</Box>
	);
};

export default DefaultCard;
