import React from "react";
import { Box, Typography, Stack, IconButton } from "@mui/joy";
import { Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useAuthContainer from "../../../../stores/Auth";
import { Link, useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import queryString from "query-string";
import Helpers from "utils/Helpers";

const ImageGallery2 = ({ setValue, values }) => {
	const { placeID } = useParams();
	const [ID, setID] = React.useState(uuidv4());
	const { t } = useTranslation();
	const { mediaLibUrl, setMediaLibUrl } = useAuthContainer.useContainer();
	const prevMediaLibUrlRef = React.useRef();
	const location = useLocation();

	const { el_id } = queryString.parse(location.search);

	React.useEffect(() => {
		if (
			mediaLibUrl !== prevMediaLibUrlRef.current &&
			mediaLibUrl !== null
		) {
			if (el_id === ID || !el_id) {
				addImage(mediaLibUrl);
				prevMediaLibUrlRef.current = mediaLibUrl;
				setMediaLibUrl(null);
			}
		}
	}, [mediaLibUrl]);

	const addImage = (imageUrl) => {
		setValue("images", [...(values ?? []), imageUrl], {
			shouldDirty: true,
		});
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("image_gallery")}
			</Typography>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					gap: "14px",
					marginTop: "18px",
					padding: "13px",
					background: "#F9F9F9",
					borderRadius: "10px",
					flexWrap: "wrap",
				}}
			>
				{values &&
					values.map((val, index) => (
						<div key={index}>
							<Stack
								direction="row"
								sx={(theme) => ({
									mb: 1,
								})}
							>
								<Box
									sx={{
										position: "relative",
										height: 100,
										width: 100,
										background: `url("${val}") no-repeat center center / cover`,
										"&:hover": {
											" > .MuiStack-root": {
												opacity: 1,
											},
										},
									}}
								>
									<Stack
										justifyContent="center"
										direction="row"
										sx={{
											ml: "4px",
											position: "absolute",
											background: "white",
											position: "absolute",
											bottom: "0",
											right: "0",
											padding: "3px",
											opacity: 0,
										}}
									>
										<IconButton
											type="button"
											onClick={() =>
												setValue(
													"images",
													values.filter(
														(im) => im !== val,
													),
													{ shouldDirty: true },
												)
											}
										>
											<Delete color="danger" />
										</IconButton>
									</Stack>
								</Box>
							</Stack>
						</div>
					))}
				<Box
					className="addImage"
					sx={(theme) => ({
						position: "relative",
						width: "100px",
						background: theme.vars.palette.primary[500],
						height: "100px",
						borderRadius: "10px",
						border: "1px solid #eee",
						color: "white",
					})}
				>
					<IconButton
						sx={{ width: "100%", height: "100%", color: "white" }}
						component={Link}
						to={Helpers.addObjectToUrl(
							`?media_lib=true&el_id=${ID}`,
							{ placeId: placeID },
						)}
					>
						<Add
							color="white"
							sx={{ color: "white", fontSize: "60px" }}
						/>
					</IconButton>
				</Box>
			</div>
		</Box>
	);
};

export default ImageGallery2;
