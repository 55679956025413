import React from "react";
import { Box, Typography, Stack } from "@mui/joy";
import { useTranslation } from "react-i18next";
import datas from "../../../../datas.json";
import Helpers from "utils/Helpers";
import { HideSectionButton } from "forms/places";

const Rse = ({ description_rse, label_rse, placeId, uid, sections }) => {
	const { t } = useTranslation();

	// Convert the datas to a more accessible object format if needed
	const labelRseObjet = datas.label_rse.reduce((acc, item) => {
		acc[item.nom] = item;
		return acc;
	}, {});

	return (
		<Box sx={{ mt: 2, mb: 2 }}>
			<Typography
				level="h4"
				sx={(theme) => ({
					mb: 2,
					textAlign: "left",
					color: theme.vars.palette.primary[600],
				})}
			>
				RSE
			</Typography>

			<HideSectionButton
				section="rse"
				placeId={placeId}
				uid={uid}
				sectionsVisibility={sections}
			/>
			<Typography
				sx={{
					whiteSpace: "pre-wrap",
				}}
				paragraph
				variant="body1"
			>
				{description_rse}
			</Typography>

			{label_rse &&
				Helpers.checkIsArray(label_rse).map((labelName) => {
					const label = labelRseObjet[labelName]; // Access the label details from the precomputed object
					return (
						<Box
							key={label.nom} // Use a unique key for React's rendering
							sx={{
								display: "flex",
								alignItems: "center",
								gap: "22px",
								background: label.color, // Use the color specified in datas
								padding: "12px",
								borderRadius: "14px",
								marginTop: "19px",
							}}
						>
							<Box>
								<img
									src={label.image}
									width={60}
									style={{ borderRadius: "10px" }}
								/>
							</Box>
							<Box>
								<Typography paragraph>
									{t(label.nom)}
								</Typography>
								<Typography
									sx={{
										whiteSpace: "pre-wrap",
									}}
								>
									{label.description}
								</Typography>
							</Box>
						</Box>
					);
				})}
		</Box>
	);
};

export default Rse;
