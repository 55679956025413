import React from "react";
import { SimpleTable } from "../../../../../../../components/UI";
import { IconButton, Switch, Typography } from "@mui/joy";
import {
	Settings,
	Edit,
	Delete,
	ContentCopy,
	RemoveRedEyeOutlined,
} from "@mui/icons-material";

import { Dropdown } from "react-bootstrap";

const EventTable = ({
	events,
	changeStatus,
	t,
	navigate,
	setShowConfirm,
	setSelectedId,
	Helpers,
}) => {
	return (
		<SimpleTable
			inputProps={{
				borderAxis: "none",
			}}
			style={{
				background: "white",
				borderRadius: "10px",
				mb: 2,
			}}
			head={["name", "pax", "type", "start", "end", "", ""]}
			datas={events}
			fields={{
				event_name: (d) => <b>{d}</b>,
				event_participants_qty: (d) => d,
				event_type: (d) => t(d),
				event_start_date: (d) =>
					d ? Helpers.fbDate(d, "DD/MM/YYYY") : "",
				event_end_date: (d) =>
					d ? Helpers.fbDate(d, "DD/MM/YYYY") : "",
			}}
			actions={[
				(d) => (
					<Typography
						component="label"
						startDecorator={
							<Switch
								checked={d.status === "active"}
								variant="solid"
								onChange={(e) => changeStatus(e, d.id)}
							/>
						}
					>
						{d?.status === "active" ? t("online") : t("offline")}
					</Typography>
				),
				(d) => (
					<Dropdown>
						<Dropdown.Toggle
							sx={{
								"&::after": {
									display: "none",
								},
							}}
							as={IconButton}
						>
							<IconButton>
								<Settings />
							</IconButton>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() =>
									navigate(`/event/${d.id}/general`)
								}
							>
								<Edit /> {t("manage")}
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => navigate(`/my_event/${d.id}`)}
							>
								<RemoveRedEyeOutlined /> {t("visualize")}
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setShowConfirm(true);
									setSelectedId(d.id);
								}}
							>
								<Delete />
								{t("delete")}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				),
			]}
		/>
	);
};

export default EventTable;
