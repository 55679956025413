import React from "react";
import { IconButton, Typography, Box, Stack, Switch } from "@mui/joy";
import { Map, ArrowBack, Add } from "@mui/icons-material";
import {
	Builder,
	WhiteContainer,
	AddSimpleImage,
} from "../../../../../../../../components/UI";
import useAuthContainer from "../../../../../../../../stores/Auth";
import useUsefullInfosContainer from "../../../../UsefullInfosStore";
import { useTranslation } from "react-i18next";

const Plan = ({ id }) => {
	const { t } = useTranslation();
	const { setEditor, setUsefullInfosState, usefullInfosState } =
		useUsefullInfosContainer.useContainer();

	return (
		<WhiteContainer>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 3 }}
				spacing={2}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<IconButton onClick={() => setEditor(null)}>
						<ArrowBack />
					</IconButton>
					<Typography>{t("back_usefull_infos")}</Typography>
				</Stack>

				<Stack direction="row" spacing={1} alignItems="center">
					<Switch
						checked={usefullInfosState?.map?.active}
						onChange={(e) => {
							const v = e.target.checked;
							setUsefullInfosState({
								...usefullInfosState,
								map: {
									...usefullInfosState?.map,
									active: v,
								},
							});
						}}
					/>

					<Typography
						startDecorator={<Map />}
						level="h5"
						sx={(theme) => ({
							textTransform: "uppercase",
							background: usefullInfosState?.map?.active
								? theme.vars.palette.primary[400]
								: "#EEE",
							color: usefullInfosState?.map?.active
								? "white"
								: "initial",
							borderRadius: "200px",

							pl: 2,
							pr: 2,
						})}
					>
						{t("map")}
					</Typography>
				</Stack>
			</Stack>
			<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
				<AddSimpleImage
					style={{
						width: "100%",
						height: "400px",
					}}
					icon={<Add />}
					remove={() =>
						setUsefullInfosState({
							...usefullInfosState,
							map: {
								...usefullInfosState?.map,
								map_img: null,
							},
						})
					}
					action={(img) => {
						setUsefullInfosState({
							...usefullInfosState,
							map: {
								...usefullInfosState?.map,
								map_img: img,
							},
						});
					}}
					image={usefullInfosState?.map?.map_img}
				/>
			</Box>
		</WhiteContainer>
	);
};

export default Plan;
