import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/joy";
import PropTypes from "prop-types";

const View = ({
	adresse,
	code_postal,
	description,
	nom,
	pays,
	region,
	ville,
	type_lieu,
	featured_image,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					mb: 3,
					height: 200,
					width: 300,
					borderRadius: 5,
					background: `url("${featured_image}") no-repeat center center / cover`,
				}}
			></Box>
			<Stack spacing={2} sx={{}}>
				<Typography>
					<b>{t("name")} : </b>
					{nom}
				</Typography>

				<Typography>
					<b> {t("description ")}: </b>
					{description}
				</Typography>

				<Typography>
					<b> {t("address")}: </b>
					{adresse}
				</Typography>
				<Typography>
					<b> {t("place_type")}: </b>
					{t(type_lieu)}
				</Typography>
			</Stack>
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
