import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack, Tooltip } from "@mui/joy";
import { Hotel, LocationOn } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const PlacesTemplateCard = ({
	title,
	image,
	location,
	type,
	admin,
	description,
	beds,
	style,
	id,
	quoteDisabled,
	visualizeLink,
	setSelect,
	addToFavorite,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={(theme) => ({
				height: "100%",
				position: "relative",
				overflow: "hidden",

				...style,
			})}
			onMouseEnter={() => setSelect && setSelect(id)}
		>
			<Box
				sx={{
					position: "absolute",
					top: 5,
					right: 5,
					background: "white",
					borderRadius: 100,
				}}
			>
				{addToFavorite}
			</Box>
			<Stack
				alignItems="center"
				justifyContent="space-between"
				spacing={2}
				sx={{
					height: "100%",
				}}
			>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						//flexDirection: { md: "column-reverse", xl: "row" },
						flexDirection: "column-reverse",
						columnGap: "5px",
					}}
				>
					<Stack spacing={1} sx={{ flexGrow: 1, p: 1 }}>
						<Typography level="h5">
							<strong>{title}</strong>
						</Typography>
						<div>
							{location && (
								<Typography>
									<LocationOn color="primary" /> {location}
								</Typography>
							)}

							{beds && (
								<Typography>
									<Hotel color="primary" /> {beds}
								</Typography>
							)}
						</div>
					</Stack>
					<Box
						sx={{
							height: "150px",
							borderRadius: "5px 5px 0 0  ",
							width: "100%",
							background: `url("${image}") no-repeat scroll center center / cover`,
						}}
					></Box>
				</Box>
				<Box>
					<Stack
						sx={{
							mb: 1,
							".MuiButton-root": {
								pl: 4,
								pr: 4,
							},
						}}
						direction="row"
						spacing={1}
					>
						{!quoteDisabled && <Button>{t("quote")}</Button>}
						<Button
							sx={(theme) => ({
								background: theme.vars.palette.primary[50],
							})}
							variant="default"
							component={Link}
							to={visualizeLink}
						>
							{t("visualize")}
						</Button>
						{admin && (
							<Button
								variant="solid"
								component={Link}
								to={`/admin/places/${id}`}
							>
								{t("edit")}
							</Button>
						)}
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default PlacesTemplateCard;
PlacesTemplateCard.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	location: PropTypes.string,
	type: PropTypes.string,
	description: PropTypes.string,
	beds: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	visualizeLink: PropTypes.string,
	quoteDisabled: PropTypes.bool,
	selected: PropTypes.bool,
};
PlacesTemplateCard.defaultProps = {
	title: "",
	image: "",
	selected: false,
	visualizeLink: "/",
	location: "",
	type: "",
	description: "",
	beds: "",
	quoteDisabled: false,
	id: "",
	style: { width: "100%" },
};
