import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types"; // ES6
import { DefaultCard } from "UI";

const PlacesGrid = ({ places, setSelected }) => {
    console.log(places);
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",

                gap: "10px",
            }}
        >
            {places.map((place) => (
                <DefaultCard
                    event_name={place?.nom}
                    id={place.id}
                    setSelectedId={setSelected}
                    hideStatus
                    event_background_image={place?.featured_image}
                    editLink={`/places/edit/${place.id}`}
                    showLink={`/places/view/${place.id}`}
                />
            ))}
        </Box>
    );
};

export default PlacesGrid;

PlacesGrid.propTypes = {
    places: PropTypes.arrayOf(PropTypes.object),
};
PlacesGrid.defaultProps = {
    places: [],
};

/*
{
    "rooms": [
        {
            "id": "c8d955ce-85d2-4abe-8dae-97d5353c3ab5",
            "configurations": [
                {
                    "type": "enReunion",
                    "capacite": "12"
                },
                {
                    "capacite": "1311",
                    "type": "enU"
                },
                {
                    "capacite": "11",
                    "type": "enConference"
                },
                {
                    "type": "enRangDEcole",
                    "capacite": "11"
                },
                {
                    "capacite": "213",
                    "type": "enRond"
                },
                {
                    "type": "tableRondeGala",
                    "capacite": "10"
                },
                {
                    "type": "theatre",
                    "capacite": "0"
                },
                {
                    "capacite": "0",
                    "type": "cocktail"
                }
            ],
            "nom_salle": "fzeok",
            "options": {
                "lumiereDuJour": null
            },
            "superficie_salles": "",
            "capacite_max": "12"
        }
    ],
    "cadre": {
        "au_bord_de_la_mer": null,
        "calme": null,
        "luxe": null,
        "cocooning": true,
        "digital": null,
        "convivial": null,
        "quartier_anime": null,
        "chaleureux": null,
        "cosy": null,
        "tendance": null,
        "au_vert": null,
        "chic": null,
        "informel": null,
        "zen": null,
        "design": null,
        "eco_responsable": null,
        "festif": null,
        "lounge": null,
        "intimiste": null,
        "coworking": null,
        "a_la_montagne": null,
        "au_bord_de_leau": null,
        "vue_imprenable": null,
        "feutre": null,
        "comme_a_la_maison": null,
        "art_deco": null,
        "fengshui": null,
        "moderne": true,
        "service_aux_petits_soins": null
    },
    "publish": false,
    "type_lieu": "palace",
    "code_postal": "59800",
    "description_rse": "",
    "wifi": null,
    "region": "Hauts-de-France",
    "label_rse": {
        "green_room": true,
        "eco_label_eu": true,
        "green_globe": true,
        "clef_verte": true
    },
    "createdAt": {
        "seconds": 1711462310,
        "nanoseconds": 451000000
    },
    "distance_points_interet": "dù^z",
    "restauration": [
        {
            "id": "1250c091-4764-4ac5-b470-d5cb0e210f83",
            "image": "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FDALL%C2%B7E%202023-11-11%2012.06.38.png?alt=media&token=7962c093-1224-4e79-998a-1e444ff77bdc",
            "nom": "zofe",
            "description": "pofez"
        }
    ],
    "acces_handicap": null,
    "pays": "Franceojo",
    "adresse": "44 Rue Benvignat, Lille, France",
    "coordonnees_gps": {
        "lat": 50.6461126,
        "lng": 3.0518144
    },
    "modifiedAt": {
        "seconds": 1711462310,
        "nanoseconds": 451000000
    },
    "hebergement_proximite": "",
    "nombre_de_chambres": "",
    "uid": "ZCq7EJsfDybIWRxJRsKlrXjK6jZ2",
    "nombre_couchage_indiv": "",
    "ville": "Lille",
    "tarifs_journee_etude": "23243434",
    "tarifs": "",
    "parking": null,
    "equipement_technique": {
        "scene": null,
        "ecran_de_projection": null,
        "videoprojecteur": null,
        "regie_son_et_lumiere": true,
        "bloc_note_et_stylo": false,
        "microphone": false,
        "tableaux_blancs_interactifs": true,
        "technologie_click_and_share": null,
        "sonorisation": false,
        "wifi": false,
        "visio_conference": true,
        "ecran": null,
        "paperboard": false
    },
    "description": "",
    "nombre_couchage_max": "",
    "nom": "Mon super lieux",
    "images": [
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-03%20234946.png?alt=media&token=a7e074e9-5132-4006-a06c-40e1c223469b",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Falexander-krivitskiy-KtS7Fu48LLw-unsplash.jpg?alt=media&token=8f841c90-b46e-43d2-91a5-0fc2bc24ec4f",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Fannie-spratt-llEYbT9FAUQ-unsplash.jpg?alt=media&token=f8c3eb1d-33a2-4a3d-8f59-099cdb224115",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Fisaak-alexandre-karslian-EwHquRpv-9A-unsplash.jpg?alt=media&token=d6eb7237-c6fb-4d03-8fb9-7fcb5f69ad88",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Famr-taha-War-Oh9xtRI-unsplash.jpg?alt=media&token=880015c1-de54-4697-bcb2-2a90c650c060",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-03%20235256.png?alt=media&token=65b88c7d-8eaa-4ce7-88be-7ea8f2ef6996",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-03%20235504.png?alt=media&token=20d5ba52-5908-475e-b282-59ed75467c7d",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-02%20120231.png?alt=media&token=3921e9c7-aed0-4677-8f0b-99d391b768fd",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Fjames-genchi-G-qAgQLSva8-unsplash.jpg?alt=media&token=ff18fa41-c409-4e58-b262-bc50543a9591",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-03%20235420.png?alt=media&token=29e93db1-26d1-4558-88f6-9ffb304fef5f",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2Fpriyanka-singh-Jt72_opb38I-unsplash.jpg?alt=media&token=5d9d7ddd-ff8f-4262-963d-9c92c58c02ca",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FDALL%C2%B7E%202023-11-11%2012.06.38.png?alt=media&token=7962c093-1224-4e79-998a-1e444ff77bdc",
        "https://firebasestorage.googleapis.com/v0/b/weevuptools.appspot.com/o/uploads%2FZCq7EJsfDybIWRxJRsKlrXjK6jZ2%2FCapture%20d'%C3%A9cran%202023-11-03%20235256.png?alt=media&token=65b88c7d-8eaa-4ce7-88be-7ea8f2ef6996"
    ],
    "description_hebergement": "",
    "activites_loisir": {
        "escape_game": null,
        "piscine": null,
        "creatif_artistique": null,
        "randonnees": null,
        "piste_de_danse": null,
        "hammam": null,
        "espace_detente": null,
        "tennis_badminton_ping_pong": null,
        "spa": null,
        "flipper": null,
        "multi_activites_olympiades": null,
        "salle_de_fitness": null,
        "aerien": null,
        "oenologie": null,
        "billard": null,
        "piscine_interieure": null,
        "multi_activites_nautique": null,
        "bien_etre": null,
        "karaoke": null,
        "babyfoot": null,
        "murder_party_enquete": null,
        "terrain_de_petanque": null,
        "cours_de_cuisine_gastronomie": null,
        "jacuzzi": null,
        "parcours_de_sante": true,
        "golf": null,
        "rallye_chasse_au_tresor": null,
        "vtt": null,
        "sauna": true,
        "sports_mecaniques_karting": true
    },
    "description_salles": "",
    "tags": [],
    "capacite_en_twin": "",
    "tarifs_seminaire_residentiel": "2332",
    "id": "a0u3pTtwUQm9iavgqFfs"
}
*/
