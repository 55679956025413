import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Stack } from "@mui/joy";
import { Link, useLocation } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import useAuthContainer from "stores/Auth";
import queryString from "query-string";
import Helpers from "utils/Helpers";

const AddSimpleImage = ({
	style,
	icon,
	remove,
	action,
	image,
	label,
	additionnalDatas = {},
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { el_id } = queryString.parse(location.search);

	const { mediaLibUrl, setMediaLibUrl } = useAuthContainer.useContainer();
	const prevMediaLibUrlRef = React.useRef();
	const [ID, setID] = React.useState(uuidv4());

	React.useEffect(() => {
		if (
			mediaLibUrl !== prevMediaLibUrlRef.current &&
			mediaLibUrl !== null
		) {
			if (el_id === ID || !el_id) {
				action && action(mediaLibUrl);
				prevMediaLibUrlRef.current = mediaLibUrl;
				setMediaLibUrl(null);
			}
		}
	}, [mediaLibUrl]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				border: "1px solid #eee",
				borderRadius: "17px",
				...style,
			}}
		>
			{image ? (
				<Box
					className="image-container"
					sx={{
						background: `url("${image}") no-repeat center center / cover`,
						width: "100%",
						height: "100%",
						position: "relative",
					}}
				>
					<Stack
						sx={{
							top: "10px",
							position: "absolute",
							zIndex: 1,
							right: "10px",
						}}
						spacing={2}
					>
						<IconButton
							variant="solid"
							color="danger"
							onClick={remove}
						>
							<Delete />
						</IconButton>
						<IconButton
							variant="solid"
							color="primary"
							component={Link}
							to={Helpers.addObjectToUrl(
								`?media_lib=true&el_id=${ID}`,
								additionnalDatas,
							)}
						>
							<Edit />
						</IconButton>
					</Stack>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						textDecoration: "none",
						textTransform: "uppercase",
						".MuiSvgIcon-root": {
							fontSize: "50px",
						},
						"&:hover": {
							opacity: "0.6",
						},
					}}
					component={Link}
					to={Helpers.addObjectToUrl(
						`?media_lib=true&el_id=${ID}`,
						additionnalDatas,
					)}
				>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						{icon}
						{label && <Typography>{label}</Typography>}
					</Stack>
				</Box>
			)}
		</Box>
	);
};

export default AddSimpleImage;
