import React from "react";
import { Box, Typography, Stack, Tooltip } from "@mui/joy";
import { useTranslation } from "react-i18next";
import datas from "../../../../datas.json";

const MaxConfigSalle = ({ salles }) => {
	const { t } = useTranslation();
	function getMaxCapacites(salles) {
		// Utilisez `reduce` pour parcourir chaque salle et chaque configuration
		const capacitesMax = salles.reduce((acc, salle) => {
			salle.configurations.forEach((conf) => {
				// Convertissez la capacité en nombre (et traitez les valeurs non numériques comme 0)
				const capacite = parseInt(conf.capacite, 10) || 0;

				// Si le type de configuration n'existe pas dans l'accumulateur ou si la capacité actuelle est plus grande, mettez à jour l'accumulateur
				if (!acc[conf.type] || capacite > acc[conf.type]) {
					acc[conf.type] = capacite;
				}
			});
			return acc;
		}, {}); // Initialiser l'accumulateur comme un objet vide

		// Convertissez l'objet des capacités maximales en un tableau d'objets
		return Object.entries(capacitesMax).map(([type, capacite]) => ({
			type,
			capacite,
		}));
	}

	return (
		<Box sx={{ mt: 2, mb: 2 }}>
			{/*<Typography level="h">{t("max_capacity_per_config")}</Typography>*/}
			<Box
				sx={{
					p: 1,
					pl: 3,
					pr: 3,
					border: "1px solid #eee",
					borderRadius: "5px",

					display: "flex",
					width: "100%",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{salles &&
					getMaxCapacites(salles)
						.filter((f) => f.capacite > 0)
						.map((config) => (
							<Stack justifyContent="center" alignItems="center">
								<Tooltip title={t(config.type)} placement="top">
									<img
										src={datas.conf_salle[config.type]}
										width="30px"
									/>
								</Tooltip>
								<Typography sx={{ mt: 1 }}>
									{t(config.capacite)}
								</Typography>
							</Stack>
						))}
			</Box>
		</Box>
	);
};

export default MaxConfigSalle;
