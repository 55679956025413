import React from "react";
import { EventGridItem } from "./partials";
import { Box } from "@mui/joy";
const EventGrid = ({
	events,
	changeStatus,
	t,
	navigate,
	setShowConfirm,
	setSelectedId,
	Helpers,
}) => {
	console.log(events);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",

				gap: "16px",
			}}
		>
			{events &&
				events.map((event) => (
					<EventGridItem
						isModel={event?.placeId}
						changeStatus={changeStatus}
						key={event.id}
						setShowConfirm={setShowConfirm}
						setSelectedId={setSelectedId}
						{...event}
					/>
				))}
		</Box>
	);
};

export default EventGrid;
