import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import Place from "models/places";
import useAuthContainer from "stores/Auth";

const HideSectionButton = ({ sectionsVisibility, section, placeId, uid }) => {
	const { t } = useTranslation();
	const [sectionsVisibilityState, setSectionsVisibilityState] =
		React.useState(sectionsVisibility || []);
	const isHidden = sectionsVisibilityState.includes(section);
	const { user, userAccount } = useAuthContainer.useContainer();
	const isOwner = user.uid === uid || userAccount.role === "admin";

	React.useEffect(() => {
		setSectionsVisibilityState(sectionsVisibility);
	}, [sectionsVisibility]);

	const update = (datas) => {
		const PlaceEntity = new Place(datas);
		PlaceEntity.updatePlace(placeId);
	};
	const showHide = () => {
		const sections = [...sectionsVisibilityState];
		let res = [];
		if (isHidden) {
			res = sections.filter((f) => f != section);
		} else {
			res = [...sections, section];
		}
		setSectionsVisibilityState(res);
		update({ sectionsVisibility: res });
	};
	return isOwner ? (
		<>
			<Button
				onClick={() => showHide()}
				color={!isHidden ? "primary" : "neutral"}
				variant="solid"
			>
				{" "}
				{isHidden
					? t(`hide_section_${section}`)
					: t(`show_section_${section}`)}
			</Button>
		</>
	) : null;
};

export default HideSectionButton;
HideSectionButton.propTypes = {};
HideSectionButton.defaultProps = {};
