import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "UI";
import { Editable, View } from "./partials";
import PrivatePlace from "models/privatePlace";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";
import { v4 as uuidv4 } from "uuid";

const Documents = ({ documents: initialDocuments, placeId }) => {
	const { t } = useTranslation();
	const [documents, setDocuments] = useState(initialDocuments);
	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(false);
	const { user, userAccount } = useAuthContainer.useContainer();
	const PrivatePlaceEntity = React.useMemo(
		() => new PrivatePlace(placeId),
		[placeId],
	);

	useEffect(() => {
		setDocuments(initialDocuments);
	}, [initialDocuments]);

	const saveDocument = useCallback(
		async (data) => {
			console.log("selected", selected);

			// Si on est en mode édition (document sélectionné), on garde l'ID existant
			const updatedDocuments = selected
				? Helpers.deepArrayUpdate(
						documents,
						{ ...data, id: selected.id }, // Utilise l'ID existant lors de l'édition
						selected.id, // Passe l'ID existant à la fonction d'update
				  )
				: [
						...documents,
						{
							by: `${userAccount?.additionalDatas?.name || ""} ${
								userAccount?.additionalDatas?.lastname || ""
							}`,
							uid: user.uid,
							...data,
							id: uuidv4(), // Génère un nouvel ID uniquement pour les nouveaux documents
						},
				  ];

			setDocuments(updatedDocuments);
			PrivatePlaceEntity.documents = updatedDocuments;
			console.log(updatedDocuments);

			await PrivatePlaceEntity.updatePrivateDocument("datas");
			setSelected(null); // Réinitialiser après la sauvegarde
		},
		[
			documents,
			selected,
			user.uid,
			userAccount?.additionalDatas,
			PrivatePlaceEntity,
		],
	);
	const deleteDocument = useCallback(
		async (id) => {
			const updatedDocuments = documents.filter(
				(document) => document.id !== id,
			);
			setDocuments(updatedDocuments);
			PrivatePlaceEntity.documents = updatedDocuments;
			await PrivatePlaceEntity.updatePrivateDocument("datas");
		},
		[documents, PrivatePlaceEntity],
	);

	return (
		<WhiteContainer title={t("documents")}>
			<View
				documents={documents}
				select={(doc) => {
					setSelected(doc);
					setOpen(true);
				}}
			/>
			{
				<Editable
					setSelected={setSelected}
					deleteDocument={deleteDocument}
					saveDocument={saveDocument}
					open={open}
					close={() => {
						setOpen(false);
						setSelected(null);
					}}
					selected={selected}
				/>
			}
		</WhiteContainer>
	);
};

export default Documents;
