import FirestoreService from "services/firestoreServices";
import cloudFunctionsServices from "services/cloudFunctionsServices";

import { where } from "firebase/firestore";

/**
 * Creates an instance of the User class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 * @param {Array} params.favorites - List of favorite items.
 * @param {Boolean} params.isApproved - Approved or not.
 * @param {Object} params.additionalDatas - donnée aditionnel.
 * @param {Array} params.accessiblePlacesMedias - Les id des lieux dont les medias seront accessible par l'utilisateurs.
 * @param {string} params.role - user role.
 */
class User {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService("users");
        this.createUserFunction = new cloudFunctionsServices(
            "users-createUser",
        );
    }

    async getUser(uid) {
        try {
            const user = await this.firestoreService.getDocument(uid);
            return user;
        } catch (error) {
            console.error("Failed to fetch user:", error);
            throw error;
        }
    }
    async getUsers(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                10,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch users:", error);
            throw error;
        }
    }

    async addUser() {
        try {
            const user = await this.firestoreService.addDocument(this.toJSON());
            return { id: user.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add user:", error);
            throw error;
        }
    }

    async addNewUser(datas) {
        try {
            const result = await this.createUserFunction.callFunction(datas);
            return result;
        } catch (error) {
            console.error("Failed to add user:", error);
            throw error;
        }
    }

    async deleteUser(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete user:", error);
            throw error;
        }
    }

    async updateUser(id) {
        try {
            const updatedUser = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
            );
            return updatedUser;
        } catch (error) {
            console.error("Failed to update user:", error);
            throw error;
        }
    }

    toJSON() {
        const data = {};
        const properties = {
            favorites: this.favorites,
            modified_at: this.modified_at,
            additionalDatas: this.additionalDatas,
            accessiblePlacesMedias: this.accessiblePlacesMedias,
            role: this.role,
            isApproved: this.isApproved,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default User;
