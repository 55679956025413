import React from "react";
import { Box, Typography, Stack, IconButton } from "@mui/joy";
import { Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useAuthContainer from "stores/Auth";
import { Link } from "react-router-dom";

const ImageGallery2 = ({ update, label, defaultValue, limit }) => {
	const { t } = useTranslation();
	const { mediaLibUrl, setMediaLibUrl } = useAuthContainer.useContainer();
	const prevMediaLibUrlRef = React.useRef();
	const [images, setImages] = React.useState(defaultValue);

	React.useEffect(() => {
		if (
			mediaLibUrl !== prevMediaLibUrlRef.current &&
			mediaLibUrl !== null
		) {
			addImage(mediaLibUrl);
			prevMediaLibUrlRef.current = mediaLibUrl;
			setMediaLibUrl(null);
		}
	}, [mediaLibUrl]);
	const showAdd = images ? (!limit ? true : images?.length < limit) : true;

	const addImage = (imageUrl) => {
		const newImageList = [...(images ?? []), imageUrl];
		save(newImageList);
	};
	const deleteImage = (val) => {
		const newImageList = images.filter((image) => image !== val);
		save(newImageList);
	};

	const save = (list) => {
		setImages(list);
		update({ [label]: list });
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("image_gallery")}
			</Typography>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					gap: "14px",
					marginTop: "18px",
					padding: "13px",
					background: "#F1F1F1",
					borderRadius: "10px",
					flexWrap: "wrap",
				}}
			>
				{images &&
					images.map((val, index) => (
						<div key={index}>
							<Stack
								direction="row"
								sx={(theme) => ({
									mb: 1,
								})}
							>
								<Box
									sx={{
										position: "relative",
										height: 80,
										width: 80,
										background: `url("${val}") no-repeat center center / cover`,
										"&:hover": {
											" > .MuiStack-root": {
												opacity: 1,
											},
										},
									}}
								>
									<Stack
										justifyContent="center"
										direction="row"
										sx={{
											ml: "4px",
											position: "absolute",
											background: "white",
											position: "absolute",
											bottom: "0",
											right: "0",
											padding: "3px",
											opacity: 0,
										}}
									>
										<IconButton
											type="button"
											onClick={() => deleteImage(val)}
										>
											<Delete color="danger" />
										</IconButton>
									</Stack>
								</Box>
							</Stack>
						</div>
					))}
				{showAdd && (
					<Box
						className="addImage"
						sx={(theme) => ({
							position: "relative",
							width: "80px",
							background: theme.vars.palette.primary[500],
							height: "80px",
							borderRadius: "10px",
							border: "1px solid #eee",
							color: "white",
						})}
					>
						<IconButton
							sx={{
								width: "100%",
								height: "100%",
								color: "white",
							}}
							component={Link}
							to="?media_lib=true"
						>
							<Add
								color="white"
								sx={{ color: "white", fontSize: "60px" }}
							/>
						</IconButton>
					</Box>
				)}
			</div>
		</Box>
	);
};

export default ImageGallery2;
