import React from "react";
import useAuthContainer from "stores/Auth";
import { withGlobalContainer } from "stores/Global";
import { SavingDocumentLoader } from "../../UI";
import { useHistory } from "react-router-dom";
import { Box, Grid, Button, Typography } from "@mui/joy";
import { Container } from "@mui/material";
import { Header, Menu, Footer, EventMenu } from "./partials";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RequestDocumentForm } from "forms/documents";

const BOLayout = ({
	children,
	title,
	showEventMenu,
	isDocumentStatusMonitoring,
	documentStatus,
	documentID,
	backTo,
	noLateralMenu,
	admin,
}) => {
	const { user, userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();
	const matches = useMediaQuery("(max-width:850px)");
	const [menuW, setMenuW] = React.useState("250px");
	const navigate = useHistory();
	const [showMenu, setShowMenu] = React.useState(false);
	const [loginBackDrop, setLoginBackDrop] = React.useState(false);

	React.useEffect(() => {
		if (!user || !userAccount) {
			setLoginBackDrop(true);
		} else {
			setLoginBackDrop(false);
		}

		if (user && userAccount && !userAccount?.additionnalDatasAdded) {
			navigate.push("/login/informations");
		}
	}, [user, userAccount]);

	const menu_style = {
		width: matches ? "80vw" : ` calc(${menuW} - 1px)`,
		transition: "ease 500ms ",
		borderRight: "1px solid #eee",
		height: "100vh",
		position: "fixed",
		overflow: "auto",
		zIndex: 1003,
		display: noLateralMenu && "none",
		transform: showMenu ? "translateX(0)" : matches && "translateX(-150vw)",
	};

	return loginBackDrop ? (
		<Box
			sx={{
				display: loginBackDrop ? "block" : "none",
				position: "fixed",
				zIndex: 4000000,
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				background: "white",
				p: 2,
			}}
		>
			<Typography>{t("you_need_to_be_connected")}</Typography>
			<Button onClick={() => navigate.push("/login")}>
				{t("connect")}
			</Button>
		</Box>
	) : (
		<Box>
			<Box
				sx={{
					position: "fixed",
					zIndex: showMenu ? 0 : 1,
					background: "white",
					right: 0,
					zIndex: 3,
					width: noLateralMenu
						? "100%"
						: matches
						? "100%"
						: `calc(100% - ${menuW} )`,
				}}
			>
				<Header
					setShowMenu={setShowMenu}
					showMenu={showMenu}
					isMobile={matches}
					title={t(title)}
					userAccount={userAccount}
					isDocumentStatusMonitoring
					documentStatus={documentStatus}
					backTo={backTo}
				/>
			</Box>

			<Box
				sx={(theme) => ({
					display: "flex",
					position: "fixed",
					width: "100%",
					overflow: "auto",
					background: theme.vars.palette.primary[10],
				})}
			>
				{showMenu && matches && (
					<Box
						onClick={() => setShowMenu(false)}
						sx={{
							background: "rgba(0,0,0,0.6)",
							position: "fixed",
							height: "100vh",
							width: "100vw",
							top: 0,
							left: 0,
							zIndex: 1000,
						}}
					/>
				)}
				<Box
					sx={{
						background: "white",
					}}
				>
					{showEventMenu ? (
						<EventMenu id={documentID} style={menu_style} />
					) : (
						<Menu
							isPartner={userAccount?.isApproved}
							isAdmin={userAccount?.role === "admin"}
							style={menu_style}
							admin={admin}
						/>
					)}
				</Box>
				<Box
					sx={(theme) => ({
						m: `0px auto  auto ${
							noLateralMenu ? "0px" : !matches ? menuW : ""
						}  !important`,

						pt: 12,
						pr: !matches && 2,
						pl: !matches && 2,
						width: matches ? "97%" : "100%",
						transition: "ease 500ms",
						height: "100vh",
						position: "relative",
					})}
				>
					{children}
				</Box>
			</Box>
			<RequestDocumentForm
				uid={user?.uid}
				open={
					!userAccount?.isApproved &&
					userAccount?.additionalDatas?.profil_type ===
						"PartnerLocation"
				}
			/>
		</Box>
	);
};

export default withGlobalContainer(BOLayout);
