import React from "react";
import { Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Element = ({
	style,
	image,
	title,
	id,
	eventUID,
	description,
	start_hour,
	end_hour,
	selectElement,
	isSubcatecoryItem,
}) => {
	const { t } = useTranslation();
	const dark = style?.theme_mode === "dark";
	return (
		<Box
			id={id}
			sx={{
				width: "92%",
				m: "0 auto",
				borderBottom: "1px solid #eee",
				background: dark
					? "#111"
					: isSubcatecoryItem
					? style?.no_theme_color_background
						? "white"
						: `${style?.theme_color}14`
					: "white",
				borderRadius: "10px",
				mt: 1,
				border: dark ? "none" : "1px solid #eee",
			}}
			onClick={selectElement}
		>
			<Box
				sx={{
					p: "18px 23px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					".MuiTypography-root": {
						color:
							style?.theme_mode === "dark" ? "white" : "initial",
					},
				}}
			>
				<Box sx={{ maxWidth: "70%" }}>
					<Typography
						sx={{
							fontSize: "16px",
							mb: 1,
							fontWeight: "bold",
							maxWidth: "calc(100vw - 151px)",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						{title}
					</Typography>
					{(start_hour || end_hour) && (
						<Typography>
							<span style={{ color: style?.theme_color }}>
								{t("hours")}
							</span>{" "}
							: {start_hour && start_hour.replace(":", "h")}
							{end_hour && ` - ${end_hour.replace(":", "h")}`}
						</Typography>
					)}
					<Typography
						sx={{
							maxWidth: "calc(100vw - 151px)",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						{description}
					</Typography>
				</Box>
				<Box
					sx={{
						borderRadius: "12px",
						width: "80px",
						height: "80px",
						background: `url("${image}") no-repeat center center / cover`,
					}}
				/>
			</Box>
		</Box>
	);
};

export default Element;
