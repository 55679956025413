import React from "react";
import { Switch, Route } from "react-router-dom";
import {
	PlaceList,
	PlaceCreate,
	PlaceEdit,
	PlaceView,
	PlaceSearch,
} from "./views";
import { BOLayout } from "Components/layouts";
import { useLocation } from "react-router-dom";

const PlacesRoutes = (props) => {
	const [docId, setDocId] = React.useState(null);
	const loc = useLocation();
	return (
		<Switch>
			<>
				<BOLayout
					documentID={docId}
					noLateralMenu={loc.pathname.includes("/places/view/")}
					isDocumentStatusMonitoring
					title={"places"}
				>
					<Route exact path="/places">
						<PlaceList />
					</Route>
					<Route exact path="/places/create">
						<PlaceCreate />
					</Route>
					<Route exact path="/places/search">
						<PlaceSearch />
					</Route>
					<Route path="/places/edit/:placeID">
						<PlaceEdit />
					</Route>
				</BOLayout>
				<Route path="/places/view/:placeID">
					<PlaceView />
				</Route>
			</>
		</Switch>
	);
};

export default PlacesRoutes;
