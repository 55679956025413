import React from "react";
import { Grid, Button } from "@mui/joy";
import { NavLink } from "react-router-dom";
import useAuthProfile from "./containers/authentification/hooks/useAuthProfile";

const ModulesAccess = (props) => {
	const { logout } = useAuthProfile();

	return (
		<>
			<Grid
				sx={{
					display: "none",
					position: "fixed",

					zIndex: 20000,
					bottom: 0,
					right: 0,
					background: "white",
					opacity: 0.1,
					"&:hover": {
						opacity: 1,
					},
				}}
			>
				<ul className="">
					<li>Modules authentification : </li>

					<ul className="hover:text-LightGreen">
						<li>
							<NavLink to="/login">Connexion</NavLink>
						</li>
						<li>
							<NavLink to="/login/create-account">
								Créer mon compte
							</NavLink>
						</li>
						<li>
							<NavLink to="/login/reset-password">
								Réinitialiser mot de passe
							</NavLink>
						</li>

						<li>
							<a href="#" onClick={logout}>
								se déconnecter
							</a>
						</li>
					</ul>

					<li>Modules Dashboard : </li>
					<ul className="hover:text-LightGreen">
						<li>
							<NavLink to="/Dashboard">Dashboard</NavLink>
						</li>
					</ul>

					<li>Modules mon compte : </li>
					<ul className="hover:text-LightGreen">
						<li>
							<NavLink to="/account">Mon compte</NavLink>
						</li>
					</ul>
					<li>Modules Evènement : </li>
					<ul className="hover:text-LightGreen">
						<li>
							<NavLink to="/event/list">
								Liste des évènements
							</NavLink>
						</li>

						<li>
							<NavLink to="/event/new-event">
								Créer un évènement
							</NavLink>
						</li>
					</ul>
				</ul>
			</Grid>
		</>
	);
};

export default ModulesAccess;
