import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Snackbar, Grid, Button } from "@mui/joy";
import PropTypes from "prop-types";
import {
	Informations,
	Contacts,
	Notes,
	Invoicing,
	Documents,
} from "forms/admin/places/editForm";
import Place from "models/places";
import PrivatePlace from "models/privatePlace";
import { ArrowBack } from "@mui/icons-material";
import { useParams, Link } from "react-router-dom";

const PlaceDetails = (props) => {
	const { placeID } = useParams();
	const { t } = useTranslation();
	const PlaceEntity = new Place();
	const PrivatePlaceEntity = new PrivatePlace(placeID, {});
	const [datas, setDatas] = React.useState(null);
	const [privateDatas, setPrivateDatas] = React.useState(null);
	const [message, setMessage] = React.useState({ open: false, message: "" });

	const getPlace = async () => {
		const place = await PlaceEntity.getPlace(placeID);

		setDatas(place);

		try {
			const privateDatas = await PrivatePlaceEntity.getPrivateDocument(
				"datas",
			);
			setPrivateDatas(privateDatas);
		} catch (e) {
			setMessage({
				open: true,
				color: "danger",
				message: e.message,
				autoHideDuration: 2000,
			});
		}
	};

	React.useEffect(() => {
		getPlace();
	}, [placeID]);

	return (
		<>
			<Button sx={{ mb: 1 }} component={Link} to="/admin/places/">
				<ArrowBack />
				{t("back_to_places_list")}
			</Button>
			<Informations placeId={placeID} datas={datas} />

			<Grid container spacing={2}>
				<Grid md={4}>
					<Contacts
						contacts={privateDatas?.contacts || []}
						placeId={placeID}
					/>
					<Documents
						placeId={placeID}
						documents={privateDatas?.documents || []}
					/>
				</Grid>

				<Grid md={4}>
					<Notes
						notes={privateDatas?.notes || []}
						placeId={placeID}
					/>
				</Grid>
				<Grid md={4}>
					<Invoicing
						invoice={privateDatas?.facturation}
						placeId={placeID}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default PlaceDetails;

PlaceDetails.propTypes = {};
PlaceDetails.defaultProps = {};
