import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import {
	Apartment,
	FmdGood,
	LocalHotel,
	Nightlife,
	Euro,
	Landscape,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { FilterContainer, SliderAlgolia } from "Components/algolia";
import { RefinementList, RangeInput } from "react-instantsearch";

const PlaceFilters = () => {
	const { t } = useTranslation();

	const transformTypeLieu = (items) => {
		return items.map((item) => ({
			...item,
			label: t(item.label),
		}));
	};

	return (
		<FilterContainer
			filters={[
				{
					sectionTitle: "place_type",
					Icon: Apartment,
					content: [
						{
							title: "places_type",
							refinement: (
								<RefinementList
									attribute="type_lieu"
									transformItems={transformTypeLieu}
								/>
							),
						},
					],
				},
				{
					sectionTitle: "location",
					Icon: FmdGood,
					content: [
						{
							title: "Localisation",
							refinement: (
								<RefinementList
									attribute="region"
									transformItems={transformTypeLieu}
								/>
							),
						},
						{
							title: "country",
							refinement: (
								<RefinementList
									attribute="pays"
									transformItems={transformTypeLieu}
								/>
							),
						},
					],
				},
				{
					sectionTitle: "hebergement",
					Icon: LocalHotel,
					content: [
						{
							title: "nombre_couchage_max",
							refinement: (
								<SliderAlgolia
									attribute="nombre_couchage_max"
									label={t("nombre_couchage_max")}
									labelAdornment={""}
								/>
							),
						},
						{
							title: "nombre_de_chambres",
							refinement: (
								<SliderAlgolia
									attribute="nombre_de_chambres"
									label={t("nombre_de_chambres")}
									labelAdornment={""}
								/>
							),
						},
						{
							title: "nombre_couchage_indiv",
							refinement: (
								<SliderAlgolia
									attribute="nombre_couchage_indiv"
									label={t("nombre_couchage_indiv")}
									labelAdornment={""}
								/>
							),
						},
						{
							title: "capacite_en_twin",
							refinement: (
								<SliderAlgolia
									attribute="capacite_en_twin"
									label={t("capacite_en_twin")}
									labelAdornment={""}
								/>
							),
						},
					],
				},
				{
					sectionTitle: "Salles",
					Icon: Nightlife,
					content: [
						{
							title: "capacite_salle_max_type",
							refinement: (
								<RefinementList
									attribute="capacite_salle_max.type"
									transformItems={transformTypeLieu}
								/>
							),
						},
						{
							title: "capacite_salle_max_capacite",
							refinement: (
								<SliderAlgolia
									attribute="capacite_salle_max.capacite"
									label={t("nrb_places")}
									labelAdornment={""}
								/>
							),
						},
					],
				},
				{
					sectionTitle: "prices",
					Icon: Euro,
					content: [
						{
							title: "tarifs_seminaire_residentiel",
							refinement: (
								<SliderAlgolia
									attribute="tarifs_seminaire_residentiel"
									label={t("Prices")}
									labelAdornment={"€"}
								/>
							),
						},
						{
							title: "tarifs_journee_etude",
							refinement: (
								<SliderAlgolia
									attribute="tarifs_journee_etude"
									label={t("Prices")}
									labelAdornment={"€"}
								/>
							),
						},
					],
				},
				{
					sectionTitle: "mood",
					Icon: Landscape,
					content: [
						{
							title: "cadre",
							refinement: (
								<RefinementList
									attribute="cadre"
									transformItems={transformTypeLieu}
								/>
							),
						},
					],
				},
			]}
		/>
	);
};

export default PlaceFilters;
PlaceFilters.propTypes = {};
PlaceFilters.defaultProps = {};
