import React from "react";
import {
	Box,
	Typography,
	Button,
	Input,
	Modal,
	ModalDialog,
	ModalOverflow,
	Stack,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Add, Article } from "@mui/icons-material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Schéma de validation avec Yup
const validationSchema = Yup.object({
	name: Yup.string()
		.min(2, "Le nom doit contenir au moins 2 caractères")
		.required("Le nom est requis"),
});

const CreateModelFrom = ({ placeDatas, createModel }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Box>
				<Stack direction="row" spacing={2}>
					<Button
						component={Link}
						to={`/event/models/${placeDatas.id}`}
						onClick={() => setOpen(true)}
					>
						<Article />{" "}
						<Typography sx={{ color: "white" }}>
							{t("places_models")}
						</Typography>
					</Button>
					<Button onClick={() => setOpen(true)}>
						<Add />{" "}
						<Typography sx={{ color: "white" }}>
							{t("create_from_place")}
						</Typography>
					</Button>
				</Stack>
			</Box>
			<ModalModel
				addModel={createModel}
				placeDatas={placeDatas}
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
};

const handleSubmit = async (values, placeDatas, addModel) => {
	console.log(placeDatas?.cadre);

	const datas = {
		event_name: values.name,
		uid: placeDatas.uid,
		placeId: placeDatas.id,
		event_frame: placeDatas?.cadre || [],
		created_at: new Date(),
		modified_at: new Date(),
		event_start_date: null,
	};

	await addModel(datas);
};

const ModalModel = ({ open, setOpen, placeDatas, addModel }) => (
	<Modal open={open} onClose={() => setOpen(false)}>
		<ModalOverflow>
			<ModalDialog>
				<Formik
					initialValues={{ name: "" }}
					validationSchema={validationSchema}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);

						handleSubmit(values, placeDatas, addModel);
					}}
				>
					{({ errors, touched, isSubmitting }) => (
						<Form>
							<Box sx={{ marginBottom: 2 }}>
								<Field
									name="name"
									as={Input}
									placeholder="Nom"
									error={touched.name && !!errors.name}
									helperText={touched.name && errors.name}
								/>
							</Box>
							<Box sx={{ display: "flex", gap: 2 }}>
								<Button type="submit" disabled={isSubmitting}>
									Créer
								</Button>
								<Button
									variant="outlined"
									onClick={() => setOpen(false)}
								>
									Annuler
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</ModalDialog>
		</ModalOverflow>
	</Modal>
);

export default CreateModelFrom;
