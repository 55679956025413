import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch";

const searchClient = algoliasearch(
	process.env.REACT_APP_app_id,
	process.env.REACT_APP_ALGOLIA_RESTRICTED_KEY,
);

const AlgoliaContainer = ({ index, children, filters }) => {
	const { t } = useTranslation();

	return (
		<InstantSearch searchClient={searchClient} indexName={index}>
			<Configure filters={filters || ""} />
			{children}
		</InstantSearch>
	);
};

export default AlgoliaContainer;
AlgoliaContainer.propTypes = {};
AlgoliaContainer.defaultProps = {};
