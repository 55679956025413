import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Hits } from "react-instantsearch";
import { PlacesTemplateCard } from "UI/Grids";
import { AddToFavorite } from "Common/favorites";
import useAuthContainer from "stores/Auth";

const PlaceCardHit = ({
	t,
	description,
	pays,
	image,
	nom,
	selected,
	region,
	type_lieu,
	admin,
	objectID,
	nombre_couchage_max,
}) => (
	<PlacesTemplateCard
		admin={admin}
		setSelect={() => null}
		title={nom}
		selectedID={selected}
		image={image}
		location={`${region ? region : ""} ${pays ? `,${pays}` : ""}`}
		type={type_lieu}
		description={description}
		beds={nombre_couchage_max}
		id={objectID}
		quoteDisabled
		addToFavorite={
			<AddToFavorite
				id={objectID}
				featured_image={image}
				nom={nom}
				type={"places"}
				iconButton={true}
			/>
		}
		visualizeLink={`/places/view/${objectID}`}
	/>
);

const Results = ({ showMap }) => {
	const { t } = useTranslation();
	const { userAccount } = useAuthContainer.useContainer();

	return (
		<>
			<Box
				sx={{
					mt: 2,
					mb: 2,
					p: 2,
					".ais-Hits": {},
					".ais-Hits-list": {
						display: "grid",
						gap: 2,
						gridTemplateColumns: !showMap
							? {
									md: "1fr 1fr 1fr 1fr 1fr",
							  }
							: {
									lg: "1fr 1fr",
									xl: "1fr 1fr 1fr",
							  },
						".ais-Hits-item": {
							height: "350px",
							p: 0,
							display: "flex",
							overflow: "hidden",
							alignItems: "start",

							width: "100%",
						},
					},
				}}
			>
				<Hits
					hitComponent={({ hit }) => (
						<PlaceCardHit
							admin={userAccount?.role === "admin"}
							key={hit.objectID}
							t={t}
							{...hit}
						/>
					)}
				/>
			</Box>
		</>
	);
};

export default Results;
Results.propTypes = {
	showMap: PropTypes.bool,
};
Results.defaultProps = {};
