import React from "react";
import {
	BecomePartnerInfo,
	AdditionnalInfoForm,
	DeleteAccount,
} from "./partials";
import { WhiteContainer } from "../../../../components/UI";

const Account = (props) => {
	return (
		<>
			<WhiteContainer title="informations">
				<AdditionnalInfoForm />
			</WhiteContainer>
			<WhiteContainer title="delete_account">
				<DeleteAccount />
			</WhiteContainer>
			<WhiteContainer title="Partenaire">
				<BecomePartnerInfo />
			</WhiteContainer>
		</>
	);
};

export default Account;
