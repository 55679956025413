import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Builder } from "Common";
import { WhiteContainer } from "UI";
import Planning from "models/plannings";

const PlanningBuilder = ({ datas }) => {
	const { t } = useTranslation();
	const [stateDatas, setStateDatas] = React.useState(datas);
	const [saving, setSaving] = React.useState(false);

	const update = async () => {
		setSaving(true);
		try {
			const planningEntity = new Planning(stateDatas);
			const update = await planningEntity.updatePlanning(datas?.id);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return (
		<Box sx={{ position: "relative" }}>
			<WhiteContainer style={{ textAlign: "center" }}>
				<Button loading={saving} disabled={saving} onClick={update}>
					{t("save")}
				</Button>
			</WhiteContainer>
			<Builder
				content={stateDatas?.content || []}
				action={(d) => setStateDatas({ ...stateDatas, content: d })}
				editorConfig={{
					modalSize: 700,
					limitDepth: 3,
					itemsConfig: {
						default: {
							cardFields: ["title"],
							terms: {
								type: "item",
								type_plural: "items",
								create_new_item: "add_item",
								create_new_children: "create_new_element",
								detail_tab: "item_details",
								children_type: "item",
								content_tab: "item_content",
							},
							editor: {
								hasChildren: false,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
									{
										label: "start_hour",
										type: "time",
										rules: {
											required: false,
										},
									},
									{
										label: "end_hour",
										type: "time",
										rules: {
											required: false,
										},
									},
								],
							},
						},
						depth_1: {
							cardFields: ["title"],
							terms: {
								type: "sequence",
								type_plural: "sequences",
								create_new_item: "add_sequence",
								create_new_children: "create_new_element",
								detail_tab: "sequence_details",
								content_tab: "sequence_content",
								children_type: "element",
							},
							editor: {
								hasChildren: true,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
								],
							},
						},
						depth_2: {
							cardFields: ["title"],
							ifNochilds: "default",
							terms: {
								type: "category",
								type_plural: "categories",
								create_new_item: "add_category",
								create_new_children: "create_new_element",
								detail_tab: "category_details",
								children_type: "element",
								content_tab: "category_content",
							},
							editor: {
								hasChildren: true,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
								],
							},
						},
					},
				}}
			/>
		</Box>
	);
};

export default PlanningBuilder;

PlanningBuilder.propTypes = {
	datas: PropTypes.object.isRequired,
};
PlanningBuilder.defaultProps = {
	datas: {},
};
