import React from "react";
import { Box } from "@mui/joy";
import { MainCategory, SubCategory, Element } from "./partials";
import { useLocation } from "react-router-dom";
import Helpers from "../../../../../../utils/Helpers";

const PlanningList = ({ activities, style, setSelectedElement }) => {
	const { hash } = useLocation();

	React.useEffect(() => {
		if (hash) {
			const id = hash.replace("#", "");
			const element = document.getElementById(id);
			window.scrollTo({
				behavior: element ? "smooth" : "auto",
				top: element ? element.offsetTop : 0,
			});
		}
	}, []);
	const checkType = (item) =>
		Helpers.findDepthById(activities, item.id) == 2 &&
		item?.children.length > 0;
	return (
		<>
			{activities &&
				activities.map((item, index) =>
					item?.children && item?.children.length > 0 ? (
						<MainCategory style={style} {...item} key={item.id}>
							{item?.children.map((child, childIndex) =>
								checkType(child) ? (
									<SubCategory
										{...child}
										key={child.id}
										style={style}
									>
										{child?.children &&
											child?.children.map(
												(subChild, subChildIndex) => (
													<Element
														isSubcatecoryItem
														selectElement={() =>
															setSelectedElement(
																subChild,
															)
														}
														style={style}
														index={subChildIndex}
														{...subChild}
														key={subChild.id}
													/>
												),
											)}
									</SubCategory>
								) : (
									<Element
										selectElement={() =>
											setSelectedElement(child)
										}
										style={style}
										index={childIndex}
										{...child}
										key={child.id}
									/>
								),
							)}
						</MainCategory>
					) : (
						<Element
							selectElement={() => setSelectedElement(item)}
							style={style}
							index={index}
							{...item}
							key={item.id}
						/>
					),
				)}
		</>
	);
};

export default PlanningList;
