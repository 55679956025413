import React from "react";
import {
	Typography,
	FormControl,
	FormLabel,
	ListItem,
	IconButton,
	Box,
	List,
	Chip,
	Button,
	Textarea,
	Divider,
	FormHelperText,
} from "@mui/joy";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SalleModal from "./SalleModal";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import materiel_technique from "datas/places/places_technical_equipment.json";

const Salles = ({ setValue, datas, errors, register, control }) => {
	const { t } = useTranslation();
	const [rooms, setRooms] = React.useState(datas);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [editingRoom, setEditingRoom] = React.useState(null);

	React.useEffect(() => {
		if (rooms.length < 1) {
			if (datas.length > 0) {
				setRooms(datas);
			}
		}
	}, [datas]);

	const updateValues = (d) => {
		setValue("rooms", d, { shouldDirty: true });
	};

	const openModalToAdd = () => {
		setEditingRoom(null);
		setIsModalOpen(true);
	};

	const handleDelete = (index) => {
		const newRooms = rooms.filter((_, i) => i !== index);
		setRooms(newRooms);
		updateValues(newRooms);
	};
	const openModalToEdit = (room) => {
		setEditingRoom(room);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const saveRoom = (roomData) => {
		if (editingRoom) {
			const updatedRooms = rooms.map((room, index) =>
				room.id === editingRoom.id ? roomData : room,
			);

			updateValues(updatedRooms);
			setRooms(updatedRooms);
		} else {
			const newsRooms = [...rooms, { id: uuidv4(), ...roomData }];
			updateValues(newsRooms);
			setRooms(newsRooms); // Ajouter une nouvelle salle
		}
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("meeting_rooms")}
			</Typography>

			{/* Description */}
			<FormControl variant="outlined">
				<FormLabel>Description des salles</FormLabel>
				<Textarea
					{...register("description_salles")}
					placeholder="Décrivez les salles"
					multiline
					minRows={3}
				/>
				<FormHelperText>
					{errors?.description_salles?.message}
				</FormHelperText>
			</FormControl>

			{/*EQUIPEMENT */}
			<Box sx={{ mt: 2 }}>
				<FormLabel sx={{ mb: 2 }}>
					Liste des équipements disponibles.
				</FormLabel>
				<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
					{materiel_technique.map((option, index) => (
						<Controller
							key={option}
							name={`equipement_technique`}
							control={control}
							render={({
								field: { onChange, value = [], name },
							}) => (
								<Chip
									variant={
										value.includes(option)
											? "solid"
											: "outlined"
									}
									color={
										value.includes(option)
											? "primary"
											: "default"
									}
									onClick={() => {
										const newValue = value.includes(option)
											? value.filter((v) => v !== option)
											: [...value, option];

										onChange(newValue);
									}}
									label={option}
									size="md"
									sx={{
										cursor: "pointer",
										border: "1px solid #eee",
										"&:hover": {
											opacity: 0.8,
										},
									}}
								>
									{" "}
									{t(option)}
								</Chip>
							)}
						/>
					))}
				</Box>
			</Box>
			<Divider sx={{ m: 2 }} />
			<div>
				<Typography sx={{ mb: 1, mt: 2 }} component="h4">
					Liste des salles
				</Typography>
				<List sx={{ width: { md: "350px", xs: "100%" } }}>
					{rooms.map((room, index) => (
						<ListItem
							key={room.id}
							sx={{
								p: 2,
								border: "1px solid #eee",
								mb: 1,
							}}
							endAction={
								<>
									<IconButton
										onClick={() => openModalToEdit(room)}
									>
										<Edit
											variant="container"
											color="primary"
										/>
									</IconButton>
									<IconButton
										onClick={() => handleDelete(index)}
									>
										<Delete color="danger" />
									</IconButton>
								</>
							}
						>
							{room.nom_salle}
						</ListItem>
					))}
				</List>
				<Button onClick={openModalToAdd}>
					<Add />
					Ajouter une salle
				</Button>

				<SalleModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSave={saveRoom}
					defaultValues={editingRoom} // Passer les valeurs par défaut pour l'édition
				/>
			</div>
		</Box>
	);
};

export default Salles;
