import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home, UsefullInfos, Plannings, Activities, Meals } from "./views";

const FrontRoutes = () => {
	return (
		<Switch>
			<Route exact path="/my_event/:eventID">
				<Home />
			</Route>
			<Route exact path="/my_event/:eventID/usefull_infos">
				<UsefullInfos />
			</Route>
			<Route exact path="/my_event/:eventID/plannings">
				<Plannings />
			</Route>
			<Route exact path="/my_event/:eventID/activities">
				<Activities />
			</Route>
			<Route exact path="/my_event/:eventID/meals">
				<Meals />
			</Route>
		</Switch>
	);
};

export default FrontRoutes;
