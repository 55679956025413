import React from "react";
import { createGlobalStyle } from "styled-components";

import AppRoutes from "./AppRoutes";
import { Routes, Route, NavLink } from "react-router-dom";
import { withAuthContainer } from "./stores/Auth";
import ModulesAccess from "./ModulesAccess";
import MediaLibrary from "./components/common/MediaLibrary/MediaLibrary";

const App = (props) => {
  return (
    <>
      <MediaLibrary />
      <GlobalStyle />

      <ModulesAccess />
      <AppRoutes />
    </>
  );
};

export default withAuthContainer(App);

const GlobalStyle = createGlobalStyle`

body,
html {

  min-height: 100%;
  margin: 0;
  padding: 0;
}

div#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

  `;
