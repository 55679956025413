import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Item, useGallery } from "react-photoswipe-gallery";

const GalleryContent = ({ images }) => {
	const { open } = useGallery();
	const { t } = useTranslation();
	const [imageDimensions, setImageDimensions] = useState({});

	useEffect(() => {
		images.forEach((image) => {
			const img = new Image();
			img.onload = () => {
				setImageDimensions((prev) => ({
					...prev,
					[image]: { width: img.width, height: img.height },
				}));
			};
			img.src = image;
		});
	}, [images]);

	return (
		<>
			{images.length > 0 && (
				<Button onClick={() => open(0)}>
					{t("show_x_image", { images: images.length })}
				</Button>
			)}
			<div>
				{images.map((image) => (
					<Item
						original={image}
						thumbnail={image}
						width={imageDimensions[image]?.width}
						height={imageDimensions[image]?.height}
					>
						{({ ref, open }) => <div ref={ref}></div>}
					</Item>
				))}
			</div>
		</>
	);
};

export default GalleryContent;

GalleryContent.propTypes = {
	images: PropTypes.array.isRequired,
};

GalleryContent.defaultProps = {
	images: [],
};
