import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Switch,
  Divider,
  Input,
  Button,
  FormLabel,
  Sheet,
  ModalOverflow,
  FormControl,
  FormHelperText,
  Chip,
  Modal,
  ModalDialog,
  DialogTitle,
  Box,
  DialogContent,
  Stack,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

const options = ["lumiereDuJour"];

const SalleModal = ({ isOpen, onClose, onSave, defaultValues }) => {
  const { t } = useTranslation();
  const { register, control, handleSubmit, setValue, getValues, reset, watch } =
    useForm({
      defaultValues: defaultValues || {
        nom_salle: "",
        capacite_max: "",
        superficie_salles: "",
        configurations: [
          { type: "enReunion", capacite: 0 },
          { type: "enU", capacite: 0 },
          { type: "enConference", capacite: 0 },
          { type: "enRangDEcole", capacite: 0 },
          { type: "enRond", capacite: 0 },
          { type: "tableRondeGala", capacite: 0 },
          { type: "theatre", capacite: 0 },
          { type: "cocktail", capacite: 0 },
        ],
        options: {
          lumiereDuJour: false,
        },
      },
    });
  const { fields } = useFieldArray({
    control,
    name: "configurations",
  });

  React.useEffect(() => {
    reset({});
    if (isOpen && defaultValues) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const onSubmit = (data) => {
    onSave(data);
    console.log(data);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        reset({});
      }}
    >
      <ModalOverflow>
        <ModalDialog size="lg" sx={{ width: "80%" }}>
          <DialogTitle>
            {defaultValues?.nom_salle ? (
              <p>
                {" "}
                Salle:<i> {defaultValues.nom_salle}</i>
              </p>
            ) : (
              <p>Nouvelle Salle</p>
            )}
          </DialogTitle>

          <Sheet>
            <form>
              <Stack spacing={2}>
                <Box
                  sx={{ p: 2, border: "1px solid #eee", borderRadius: "10px" }}
                >
                  <Stack spacing={2}>
                    <FormControl variant="outlined">
                      <FormLabel>Nom de la salle</FormLabel>
                      <Input
                        {...register("nom_salle")}
                        label="Nom de la salle"
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <FormLabel>Capacité MAX</FormLabel>
                      <Input
                        {...register("capacite_max")}
                        label="Capacité max"
                        type="number"
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <FormLabel>Superficie (en m2)</FormLabel>
                      <Input
                        {...register("superficie_salles")}
                        label="Superficie"
                        type="number"
                      />
                    </FormControl>
                  </Stack>
                </Box>

                <Box
                  sx={{ p: 2, border: "1px solid #eee", borderRadius: "10px" }}
                >
                  <FormControl variant="outlined">
                    <FormLabel>Capacité par configurations</FormLabel>
                    <Stack spacing={2}>
                      {fields.map((field, index) => (
                        <div key={field.id}>
                          <input
                            type="hidden"
                            {...register(`configurations.${index}.type`)}
                            value={field.type} // Conservez le type de configuration
                          />
                          <FormLabel>{t(field.type)}</FormLabel>

                          <Input
                            {...register(`configurations.${index}.capacite`)}
                            type="number"
                            defaultValue={field.capacite} // Assurez-vous que la capacité est correctement définie
                          />
                        </div>
                      ))}
                    </Stack>
                  </FormControl>
                </Box>
                <Box
                  sx={{ p: 2, border: "1px solid #eee", borderRadius: "10px" }}
                >
                  <FormControl variant="outlined">
                    <FormLabel sx={{ mb: 1 }}>
                      Spécificités de la salle{" "}
                    </FormLabel>
                    {options.map((opt) => (
                      <Controller
                        name={`options.${opt}`}
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <Chip
                            variant={value ? "solid" : "container"}
                            color={value ? "primary" : "default"}
                            onClick={() => onChange(!value)}
                            label={t(opt)}
                            size="lg"
                            sx={{
                              cursor: "pointer",
                              border: "1px solid #eee",
                              "&:hover": {
                                opacity: 0.8,
                              },
                            }}
                          >
                            {t(opt)}
                          </Chip>
                        )}
                      />
                    ))}
                  </FormControl>
                </Box>

                <Button type="button" onClick={handleSubmit(onSubmit)}>
                  Sauvegarder
                </Button>
              </Stack>
            </form>
          </Sheet>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default SalleModal;
