import React from "react";
import { Table, Typography, Sheet, Box, Tooltip, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";
import datas from "../../../../datas.json";
import Helpers from "utils/Helpers";
const Rooms = ({ rooms, description_salles, equipement_technique }) => {
  const { t } = useTranslation();
  let equipement = [];

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        level="h4"
        sx={(theme) => ({
          mb: 2,
          textAlign: "left",
          color: theme.vars.palette.primary[600],
        })}
      >
        {t("meeting_rooms")}
      </Typography>

      <Typography sx={{ whiteSpace: "pre-wrap" }} paragraph variant="body2">
        {description_salles}
      </Typography>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography
          level="h5"
          sx={(theme) => ({
            mb: 2,
            textAlign: "left",
            color: theme.vars.palette.primary[600],
          })}
        >
          {t("equipement_technique")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {equipement_technique &&
            Helpers.checkIsArray(equipement_technique).map((equip) => (
              <Chip>{t(equip)}</Chip>
            ))}
        </Box>
      </Box>
      {rooms && (
        <>
          <Typography
            level="h5"
            sx={(theme) => ({
              mt: 2,
              textAlign: "left",
              color: theme.vars.palette.primary[600],
            })}
          >
            {t("room_detail")}
          </Typography>
          <Sheet
            variant="outlined"
            sx={{
              mt: 2,
              "--TableCell-height": "40px",
              // the number is the amount of the header rows.
              "--TableHeader-height": "calc(1 * var(--TableCell-height))",
              "--Table-firstColumnWidth": "80px",
              "--Table-lastColumnWidth": "144px",
              // background needs to have transparency to show the scrolling shadows
              "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
              "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
              overflow: "auto",
              background: (theme) =>
                `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
              backgroundSize:
                "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundPosition:
                "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
              backgroundColor: "background.surface",
            }}
          >
            <Table
              borderAxis="bothBetween"
              stripe="odd"
              hoverRow
              sx={{
                "& tr > *:first-child": {
                  position: "sticky",
                  left: 0,
                  boxShadow: "1px 0 var(--TableCell-borderColor)",
                  bgcolor: "background.surface",
                },
              }}
            >
              <thead>
                <tr>
                  <th>Nom de la salle</th>
                  <th>Capacité Max</th>
                  <th>Surface</th>
                  {rooms[0].configurations.map((config) => (
                    <th style={{ textAlign: "center" }} key={config.type}>
                      <Tooltip title={t(config.type)}>
                        <img src={datas.conf_salle[config.type]} width="30px" />
                      </Tooltip>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rooms.map((room) => (
                  <tr key={room.id}>
                    <td>{room.nom_salle}</td>
                    <td>{room.capacite_max}</td>
                    <td>{room.superficie_salles}</td>
                    {room.configurations.map((config) => (
                      <td key={config.type}>
                        {parseInt(config.capacite) || "n/c"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </>
      )}
    </Box>
  );
};

export default Rooms;
