import React from "react";
import { Box, Typography, Table, Sheet, Card, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";
import Helpers from "utils/Helpers";
import SwipeComponent from "Components/hoc/SwipeComponent";

const Hebergement = ({ activites }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box>
				<Typography
					level="h4"
					sx={(theme) => ({
						mb: 2,
						textAlign: "left",
						color: theme.vars.palette.primary[600],
					})}
				>
					{t("activites")}
				</Typography>

				{activites &&
					Helpers.checkIsArray(activites).map((equip) => (
						<Chip sx={{ m: "3px" }}>{t(equip)}</Chip>
					))}
			</Box>
		</>
	);
};

export default Hebergement;
