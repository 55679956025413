import React, { useEffect, useState } from "react";
import {
	Box,
	ListItemDecorator,
	ListItemContent,
	ListItemButton,
	ListItem,
	Divider,
	List,
} from "@mui/joy";
import { Logo } from "./partials";
import {
	DashboardOutlined,
	Person,
	Group,
	AdminPanelSettings,
	EventOutlined,
	Domain,
	FormatListBulletedOutlined,
	Mail,
	PermMedia,
	AccountCircleOutlined,
	PowerSettingsNewOutlined,
	Handshake,
	KeyboardArrowRight,
	KeyboardArrowDown,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Helpers from "../../../../../utils/Helpers";
import useGlobalContainer from "stores/Global";
import useAuthProfile from "../../../../../containers/authentification/hooks/useAuthProfile";

const menuItems = {
	default: [
		{
			open: false,
			label: "dashboard",
			path: "/dashboard",
			Icon: DashboardOutlined,
		},
		{
			open: false,
			label: "my_events",
			path: "/event/list",
			Icon: EventOutlined,
		},
		{
			open: false,
			label: "partner_places",
			path: "/places/search",
			Icon: Handshake,
		},
		{
			open: false,
			label: "media_lib",
			path: "?media_lib=true",
			Icon: PermMedia,
		},
		{
			open: false,
			label: "services",
			path: "/services",
			Icon: FormatListBulletedOutlined,
		},
	],
	partner: [
		{
			open: false,
			label: "dashboard",
			path: "/dashboard",
			Icon: DashboardOutlined,
		},
		{
			open: false,
			label: "my_events",
			path: "/event/list",
			Icon: EventOutlined,
		},
		{
			open: false,
			label: "my_models",
			path: "/event/models",
			Icon: EventOutlined,
		},
		{ open: false, label: "my_places", path: "/places", Icon: Domain },
		{
			open: false,
			label: "media_lib",
			path: "?media_lib=true",
			Icon: PermMedia,
		},
	],
	admin: [
		{
			open: false,
			label: "dashboard",
			path: "/admin/",
			Icon: DashboardOutlined,
		},
		{
			open: false,
			label: "admin_users",
			path: "/admin/users",
			Icon: Group,
		},
		{
			open: false,
			label: "admin_events",
			path: "/admin/events",
			Icon: EventOutlined,
		},
		{
			open: false,
			label: "admin_places",
			path: "/admin/places",
			Icon: Domain,
		},
		{
			open: false,
			label: "admin_groups",
			path: "/admin/groups",
			Icon: Domain,
		},
		{
			open: false,
			label: "admin_request",
			path: "/admin/requests",
			Icon: Mail,
		},
		{
			open: false,
			label: "media_lib",
			path: "?media_lib=true",
			Icon: PermMedia,
		},
	],
};

const subMenuItems = (admin, isAdmin, logout) => {
	const items = [
		{
			open: false,
			label: "my_account",
			path: "/account",
			Icon: AccountCircleOutlined,
		},
		{
			label: "disconnect",
			path: "/#",
			action: logout,
			Icon: PowerSettingsNewOutlined,
		},
	];
	if (admin) {
		items.push({
			open: false,
			label: "back_to_user_mode",
			path: "/",
			Icon: Person,
		});
	} else if (isAdmin && !admin) {
		items.push({
			open: false,
			label: "admin_mode",
			path: "/admin",
			Icon: AdminPanelSettings,
		});
	}
	return items;
};

const Menu = ({ style, openMenu, setOpenMenu, isPartner, admin, isAdmin }) => {
	const { logout } = useAuthProfile();
	const { t } = useTranslation();
	const { mode, setMode } = useGlobalContainer.useContainer();
	const location = useLocation();
	const rootLocation = Helpers.uriParts(location.pathname, 0);
	const [Items, setItems] = useState(
		admin
			? menuItems.admin
			: isPartner
			? menuItems.partner
			: menuItems.default,
	);

	useEffect(() => {
		setItems(
			admin
				? menuItems.admin
				: isPartner
				? menuItems.partner
				: menuItems.default,
		);
	}, [admin, isPartner]);

	const toggleList = (index) => {
		setItems((prevItems) => {
			const newItems = [...prevItems];
			newItems[index].open = !newItems[index].open;
			return newItems;
		});
	};

	const subItems = subMenuItems(admin, isAdmin, logout);

	return (
		<Box style={style} sx={{ background: "white" }}>
			<Logo />
			<List sx={{ p: 2 }}>
				{Items.map((Item, index) => (
					<ListItem
						key={index}
						sx={(theme) => ({
							mb: 1,
							flexWrap: "wrap",
							flexDirection: "column",
							alignItems: "flex-start",
							background:
								Item.parent === rootLocation &&
								Item.open &&
								theme.vars.palette.primary[600],
						})}
					>
						<ListItemButton
							onClick={() => toggleList(index)}
							variant={
								location.pathname === Item.path
									? "solid"
									: "plain"
							}
							selected={location.pathname === Item.path}
							component={NavLink}
							to={Item.path}
							sx={(theme) => ({
								"&.Mui-selected": {
									background: theme.vars.palette.primary[600],
									"&:hover": {
										background:
											theme.vars.palette.primary[200],
									},
								},
								width: "100%",
								color:
									Item.parent === rootLocation &&
									Item.open &&
									"white",
							})}
						>
							<ListItemDecorator>
								{Item.Icon && (
									<Item.Icon
										sx={{
											color:
												Item.parent === rootLocation &&
												Item.open &&
												"white",
										}}
									/>
								)}
							</ListItemDecorator>
							<ListItemContent>{t(Item.label)}</ListItemContent>
							{Item?.childrens && (
								<>
									{Item.open ? (
										<KeyboardArrowDown />
									) : (
										<KeyboardArrowRight />
									)}
								</>
							)}
						</ListItemButton>

						{Item?.childrens && Item.open && (
							<List sx={{ p: 2 }}>
								{Item.childrens.map((child, childIndex) => (
									<ListItem key={childIndex} sx={{ mb: 1 }}>
										<ListItemButton
											sx={{
												"&:hover": {
													background: "none",
													color:
														Item.parent ===
															rootLocation &&
														"white",
													fontWeight: "bold",
												},
												color:
													Item.parent ===
														rootLocation && "white",
												fontWeight:
													location.pathname ===
														child.path && "bold",
											}}
											selected={
												location.pathname === child.path
											}
											component={NavLink}
											to={child.path}
										>
											<ListItemContent>
												{t(child.label)}
											</ListItemContent>
										</ListItemButton>
									</ListItem>
								))}
							</List>
						)}
					</ListItem>
				))}
			</List>
			<Box sx={{ position: "absolute", width: "100%", bottom: "3px" }}>
				<Divider sx={{ width: "60%", margin: "0 auto " }} />
				<List sx={{ p: 2 }}>
					{subItems.map((Item, index) => (
						<ListItem
							key={index}
							sx={{
								mb: 1,
								flexWrap: "wrap",
								flexDirection: "column",
								alignItems: "flex-start",
							}}
						>
							<ListItemButton
								variant={
									location.pathname === Item.path
										? "solid"
										: "plain"
								}
								selected={location.pathname === Item.path}
								component={NavLink}
								onClick={Item?.action}
								to={Item.path}
								sx={{ width: "100%" }}
							>
								<ListItemDecorator>
									{Item.Icon && <Item.Icon />}
								</ListItemDecorator>
								<ListItemContent>
									{t(Item.label)}
								</ListItemContent>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Box>
		</Box>
	);
};

export default Menu;
