import React from "react";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	Card,
	Button,
} from "@mui/joy";
import PropTypes from "prop-types";

const PlaceInfoCard = ({ image, title, description }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Box
				sx={{
					height: "370px",
					mr: "10px",
					width: "340px",
					cursor: "pointer",
				}}
				onClick={() => setOpen(true)}
			>
				<Box
					sx={{
						background: `url("${image}") no-repeat center center / cover`,
						height: "210px",
						borderRadius: "5px",
						width: "340px",
					}}
				></Box>
				<Box sx={{ p: 2 }}>
					<Typography>
						<b>{title}</b>
					</Typography>
					<Typography
						sx={{
							height: "40px",
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
						paragraph
						variant="body2"
					>
						{description}
					</Typography>
				</Box>
			</Box>
			<Modal open={open} onClose={() => setOpen(false)}>
				<ModalDialog size="md">
					<Box
						sx={{
							background: `url("${image}") no-repeat center center / cover`,
							height: "350px",
							width: "100%",
							maxWidth: "100%",
							borderRadius: "5px",
						}}
					></Box>
					<Box sx={{ p: 2 }}>
						<Typography>
							<b>{title}</b>
						</Typography>
						<Typography sx={{}} paragraph variant="body2">
							{description}
						</Typography>
					</Box>
				</ModalDialog>
			</Modal>
		</>
	);
};

export default PlaceInfoCard;
PlaceInfoCard.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};
PlaceInfoCard.defaultProps = {
	image: "",
	title: "",
	description: "",
};
